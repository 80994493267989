.file-upload-container {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
}

.file-upload-content {
	border: 2px dashed #007bff;
	border-radius: 10px;
	min-width: 495px;
	height: 42.5%;
	min-height: 170px;
	display: flex;
	justify-content: center;
	align-items: center;
}

input[type="file"] {
	display: none;
}

.file-input-label {
	background-color: #007bff;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}

.file-input-label:hover {
	background-color: #0056b3;
}
