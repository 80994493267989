
.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
  }
  
  .file-upload-content {
    border: 2px dashed #007bff; 
    border-radius: 10px;
    padding: 100px;
    width: 50%; 
    text-align: center;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .file-input-label {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .file-input-label:hover {
    background-color: #0056b3; 
  }